<template>
	<div>
		<h2>{{ $t('horse.production') }}</h2>
		<div class="box">
			<CustomTable
				ref="table"
				:id_table="tableID"
				primaryKey="horse_id"
				:items="families_formatted"
				:hrefsRoutes="config_table_hrefs"
				:busy.sync="table_busy"
				:checkboxes="false"
				:externSlotColumns="['enfant.horse_nom']"
			>
				<template v-slot:[`custom-slot-cell(enfant.horse_nom)`]="{ data }">
					<router-link :to="{name: 'HorseFicheInfo', params: {horse_id: data.enfant.horse_id}}">
						<strike v-if="data.enfant.horse_mort == 1">{{ data.enfant.horse_nom }}</strike>
						<span v-else>{{ data.enfant.horse_nom }}</span>
					</router-link>
				</template>
			</CustomTable>
		</div>

		<ModalImportHorseDescendants ref="modal_import_horse_descendants" @submit="force_reload"/>
	</div>
</template>


<script type="text/javascript">
	import Horse from "@/mixins/Horse.js"
    import Navigation from "@/mixins/Navigation.js"
	import Common from '@/assets/js/common'

	export default {
		name: "HorseProduct",
		mixins: [Horse, Navigation],
		props: ['horse_id'],
		data () {
			return {
				families_formatted: [],
				families: [],
				table_busy: true,
				tableID: "horse_product",
				config_table_hrefs: {
					'pere.horse_nom': {
						routeUniqueName: 'HorseFicheInfoPere',
						routeName: 'HorseFicheInfo',
						params: {
							horse_id: 'pere.horse_id'
						}
					},
					'mere.horse_nom': {
						routeUniqueName: 'HorseFicheInfoMere',
						routeName: 'HorseFicheInfo',
						params: {
							horse_id: 'mere.horse_id'
						}
					},
					'enfant.horse_nom': {
						routeUniqueName: 'HorseFicheInfo',
						routeName: 'HorseFicheInfo',
						params: {
							horse_id: 'enfant.horse_id'
						}
					}
				},
				events_tab: {
					'TableAction::goToImportDescendants': () => {
						this.openModalImportHorseDescendants()
					}
				}
			}
        },
        created() {
            this.init_component()
		},
		methods: {
            async init_component() {
				this.table_busy = true
				let horse_infos = await this.getHorseById(this.horse_id)
				if(!horse_infos.horse_sire){
					this.tableID = "horse_product_sireless"
					this.$nextTick(() => {
						this.$refs.table.init_view()
					})
				}

				let raw_families = await this.getHorseChildren(this.horse_id)
				await Common.asyncForEach(raw_families, async (family) => {
					let parents = await this.get_parent_horses(family)
					family.pere = parents.pere ? parents.pere : {horse_nom: family.pere}
					family.mere = parents.mere ? parents.mere : {horse_nom: family.mere}
					this.families.push(family)
				});
				this.families_formatted = this.families.filter(f => !!f.enfant)
				this.table_busy = false
            },
			openModalImportHorseDescendants() {
				this.$refs.modal_import_horse_descendants.openModal(this.horse_id)
			},
			async get_parent_horses(family) {
				let pere =  await this.getHorseBySireEncode(family.pere_sireencode)
				let mere = await this.getHorseBySireEncode(family.mere_sireencode)
				return {pere, mere}
			},
			async force_reload() {
				await this.$sync.runRequiredWhishlist(['horse', 'horse_pedigree'])
				this.init_component()
			}
		},
		components: {
            CustomTable: () => import('GroomyRoot/components/Table/CustomTable'),
			ModalImportHorseDescendants : () => import('@/components/Horse/ModalImportHorseDescendants')
		}
	}
</script>
